
html, body { 
    height: 100%; 
    margin: 0px; 
    padding: 0px; 

    background-color: rgb(244, 241, 232);
}

.pageWrapper {
    --tw-bg-opacity: 1;
    background-color: rgb(244, 241, 232);
    height: 100%;
}

.centerWrapper {
    margin: 0 auto;
    max-width: 400px;
    padding: 0 7px 0 7px;
}

.logo {
    margin: 20px 0 20px 0;
    display: block;
    width: 135px;
}

.qr {
    display: block;
    padding: 40px 0 40px 0;
}

.payButonWrapper {
    padding: 20px 0 0 0;
    margin: 0 auto;
    width: 140px;
}

.selectBankHeaderWrapper {
    padding: 20px 5px 20px 5px;
}

.selectBankListWrapper {
    background-color: white;
    width: 100%;
    border-radius: 5px;
}

.bankLinkArrow {
    
}

.selectBankSearch {
    background-color: white;
    width: 100%;
    padding: 0 0 0 5px;
    border-radius: 5px;
    padding: 10px 20px;
}

.bankLogo {
    width: 35px;
    height: 35px;
    margin: 0 10px 0 0px;
}

.waitForStatusCircularProgress {
    margin: 0 auto;
}

.waitForStatusCircularProgressWrapper {
    padding: 50px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statusWrapper {
    padding: 50px 0 50px 0;
    display: flex;
    justify-content: left;
    align-items: center;
}