.title {
  font-size: 18px;
  color: var(--color-common-2, #333333);
}

.error {
  font-size: 16px;
  margin: 12px 0 30px 0;
  color: var(--color-error, #eb164a);
}
