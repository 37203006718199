.form {
  //position: relative;
  //padding-bottom: 10px;
}

.flexCard {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.cardForm {
  width: 100%;
}

.halfField {
  width: 134px;
}

.cvv {
  &:global.MuiInputBase-input {
    -webkit-text-security: disc;
    font-family: 'text-security-disc';
    &::-webkit-input-placeholder {
      font-family: var(--font-family, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif);
    }
    &:-ms-input-placeholder {
      font-family: var(--font-family, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif);
    }
    &:-moz-placeholder {
      font-family: var(--font-family, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif);
    }
    &::-moz-placeholder {
      font-family: var(--font-family, 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif);
    }
  }
}

.cardLine {
  min-height: 74px;
}

.payBtn.payBtn {
  margin: 24px 0;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  box-shadow: none;
  color: var(--color-white, #ffffff);
  background: var(--color-primary, #49a7e4);
  &:hover {
    background: var(--color-primary-dark, #3693d0);
  }
}

.loader.loader {
  margin-right: 8px;
}

.preloaderWrapper {
  z-index: 10;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

.preloader.preloader {
  color: var(--color-primary, #3693d0);
  //margin-bottom: 60px;
}

.preloaderText {
  //color: #3f51b5;
  color: var(--color-white, #fff);
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-top: 52px;
}

.divider.divider {
  margin: 36px 0 24px 0;
}

.cancel {
  display: block;
  font-size: 14px;
  margin: 24px auto 0 auto;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.protected {
  margin-top: 16px;
  color: var(--color-common-4, #a2adb8);
  text-align: center;
  .title {
    margin-bottom: 12px;
  }
  .text {
    font-size: 13px;
  }
}

/* verified by */

.certLogo {
  //margin: 0 auto 24px auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  //@media (max-width: 991px) {
  //  width: auto;
  //}

  animation-duration: 0.35s;
  animation-name: animate-fade;
  animation-delay: 0.35s;
  animation-fill-mode: backwards;

  * {
    width: 66px;
    height: 26px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: contain;
    margin: 10px;
  }

  .pci {
    background-image: url(./images/pci-mono.svg);
  }

  .visa {
    background-image: url(./images/visa-mono.svg);
  }

  .mastercard {
    background-image: url(./images/mastercard-mono.svg);
  }
}

.sending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 20px 42px 20px;

  width: 320px;
  //height: 248px;

  background: #ffffff;

  &.sending.sending {
    border-radius: 10px;
  }

  .caption {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: var(--color-common-5, #000000);
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--color-common-2, #000000);
    margin-bottom: 32px;
  }

  .grow {
    flex-grow: 1;
  }
}
