.otpContainer {
  height: 100vh;
  width: 100%;
  text-align: center;
  margin: 0;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #282a36;
  color: #fff;
}

.otpHeader {
  margin-top: -40px;
}

.otpField {
  /* display: flex; */
  justify-content: center;
  margin-top: 2vh;
}

.otpField input {
  width: 12%;
  margin: 1.5%;
  padding: 0;
  display: inline-block;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  max-height: 75px;
  font-size: clamp(16px, 5vw, 32px);
  aspect-ratio: 4 / 5;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #55525c;
  background: #21232d;
  font-weight: bold;
  color: #fff;
  transition: all 0.1s;

  /* Remove blue selection */
  outline: none;
  box-shadow: none;
  background-color: transparent;
  
  /* Remove tap highlight color */
  -webkit-tap-highlight-color: transparent;
}

/* Remove number spinners */
.otpField input::-webkit-inner-spin-button,
.otpField input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.otpField input {
  -moz-appearance: textfield;
}

.otpField input:focus {
  border-color: #a527ff;
  box-shadow: 0 0 2px 2px #a527ff6a;
}

/* Submit button container */
.otpSubmitContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.otpSubmitButton {
  background-color: #4a4a67; /* Dark gray with a purple tint */
  color: #fff;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  outline: none;
  box-shadow: 0px 0px 10px 0px rgba(74, 74, 103, 0.5); /* Soft shadow */
}

.otpSubmitButton:hover {
  background-color: #5e5e80; /* Slightly lighter on hover */
}

.otpSubmitButton:focus {
  outline: 2px solid #6a00ff; /* Purple outline on focus */
}

.otpSubmitButton:active {
  background-color: #3c3c54;
}

.otpSubmitButton:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* paste button */
.otpPasteContainer {
  width: 100%;
  text-align: right;
  margin-top: 2vh;
}

.otpPasteButton {
  background: none;
  border: none;
  color: #8be9fd; 
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  margin-right: 6%;
  padding: 0;
}

.otpPasteButton:hover {
  text-decoration: none;
}


/* Loader container */
.otpLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears above other elements */
}

/* Loader spinner */
.otpLoader {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.successMessage {
  padding: 12px;
  font-size: 18px;
  text-align: center;
  max-width: 400px;
  margin: 20px auto;
}

/* Error message styling */
.otpErrorMessage {
  color: #a94442; /* Soft red for text */
  padding: 15px;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  max-width: 400px;
  margin: 20px auto;
}

/* Link styles */
.termsLink {
  color: #f8f8f2;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 40px;
}

.termsLink a {
  color: #8be9fd; /* Bright blue link */
  text-decoration: none;
}

.termsLink a:hover {
  text-decoration: underline;
}

.amountTitle {
  color: #f8f8f2;
  font-size: 18px;
  margin-bottom: 8px;
}

.amount {
  font-size: 28px;
  font-width: 700;
}
