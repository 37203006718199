
body {
    background: #f5f5f5;
    margin: 0;
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-common-2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rccs.rccs {
    margin: 0;
}

.MuiFormLabel-root.Mui-error.MuiFormLabel-root.Mui-error {
    color: rgba(0, 0, 0, 0.54);
}

.MuiFormHelperText-contained.MuiFormHelperText-contained {
    margin-top: 2px;
    margin-left: 0;
    margin-right: 0;
    font-size: 12px;
}
