.form {
  position: relative;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.cardForm {
  width: 290px;
}

.halfField {
  width: 134px;
}

.payBtn.payBtn {
  margin: 24px 0 12px 0;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  display: flex;
  box-shadow: none;
  color: var(--color-white, #ffffff);
  background: var(--color-primary, #49a7e4);
  &:hover {
    background: var(--color-primary-dark, #3693d0);
  }
}
